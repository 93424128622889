import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    container: {
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',

        width: '100%'
    }
});

function GameOver(props) {
    const { classes } = props;
    return (
        <div className={classes.container}>
            <img src="assets/images/wizard4.gif" />
        </div>
    );
}

GameOver.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(GameOver);
