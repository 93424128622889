import React from 'react';
import { EddySdk, EVENTS_NAME, QuestionsReceivedEvent } from 'eddy-web-sdk';
import { GamesService } from '../../services/games/gamesService';
import GamePlayer from './GamePlayer';
import GameOver from './GameOver';

class GamePlayerContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            gameContext: null,
            gameReady: false,
            gameOver: false
        };

        this.gameComponentReadyHandler = this.gameComponentReadyHandler.bind(
            this
        );
        this.handleGameEvents = this.handleGameEvents.bind(this);

        this.gameComponent = null;
        window.gamePlayerContainer = this;
    }

    componentDidMount() {
        window.addEventListener('message', this.handleGameEvents);

        const gameid = this.getSelectedGameId();
        window.EddySdk = EddySdk;
        console.log(`EddySdk`);

        this.startNewGame(gameid);
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.handleGameEvents, false);
    }

    getSelectedGameId() {
        return typeof this.props.location.state === 'undefined' ||
            typeof this.props.location.state.gameId === 'undefined'
            ? (Math.floor(Math.random() * 100) % 3) + 1
            : this.props.location.state.gameId;
    }

    async startNewGame(gameid = -1) {
        const gameContext = await GamesService.createRandomGameContext(gameid);
        this.setState({
            gameContext: gameContext,
            gameReady: false,
            gameOver: false
        });
        EddySdk.injectQuestions(gameContext.questions);
    }

    handleGameEvents(event) {
        if (
            this.state.gameContext 
            // &&
            // this.state.gameContext.game.url.startsWith(event.origin)
        ) {
            console.debug(
                `game message received ${JSON.stringify(event.data)}`
            );

            if (event.data && event.data.name) {
                switch (event.data.name) {
                    case EVENTS_NAME.GAME_LOADED:
                        setTimeout(() => {
                            this.setState({ gameReady: true });
                        }, 200);

                        this.sendEventToGame(
                            new QuestionsReceivedEvent(
                                this.state.gameContext.questions
                            )
                        );
                        break;
                    case EVENTS_NAME.ANSWER_RECEIVED:
                        this.handleAnswerReceived(event.data);
                        break;
                    case EVENTS_NAME.QUESTIONS_COMPLETED:
                        this.handleQuestionsCompleted();

                        break;
                    default:
                        console.debug(`Uknown event from game`);
                }
            }
        }
    }
    handleAnswerReceived(e) {
        console.debug(`Answer received from game ${JSON.stringify(e)}`);
    }

    handleQuestionsCompleted() {
        this.setState({ gameOver: true });
        console.debug(`How to handle questions  ?`);
        setTimeout(() => {
            this.startNewGame();
        }, 1500);
    }
    sendEventToGame(event) {
        this.gameComponent.contentWindow.postMessage(
            JSON.parse(JSON.stringify(event)),
            this.state.gameContext.game.url
        );
    }

    gameComponentReadyHandler(e) {
        console.debug('game container gameComponentReadyHandler');
        this.gameComponent = e;
    }

    gameFinishedHandler(e) {
        console.debug('game container gameFinishedHandler');
    }

    answerReceivedHandler(e) {
        console.debug('game container answerReceivedHandler');
    }

    gameReadyHandler(e) {
        console.debug('game container gameReadyHandler');
    }

    gamePausedHandler(e) {
        console.debug('game container gamePausedHandler');
    }

    render() {
        const { gameOver, gameContext } = this.state;
        return (
            <React.Fragment>
                {!gameOver && gameContext && (
                    <GamePlayer
                        gameContext={this.state.gameContext}
                        gameReady={this.state.gameReady}
                        onGameComponentReady={this.gameComponentReadyHandler}
                    />
                )}
                {gameOver && <GameOver />}
            </React.Fragment>
        );
    }
}

export default GamePlayerContainer;
