export const SHOW_QUESTION = 'SHOW_QUESTION';

export function showQuestion() {
    return {
        type: SHOW_QUESTION,
        question: {
            type: '',
            body: '',
            correctAnswer: 0,
            answers: ['a', 'b', 'c']
        }
    };
}

export function hideQuestion() {
    return {
        type: SHOW_QUESTION,
        question: null
    };
}
