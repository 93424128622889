export class Game {
    constructor(apiModel) {
        this.gameApiModel = apiModel;
    }

    get id() {
        return this.gameApiModel.id;
    }

    get name() {
        return this.gameApiModel.title;
    }

    get description() {
        return this.gameApiModel.description;
    }

    get dimensions() {
        return {
            width: this.gameApiModel.width,
            height: this.gameApiModel.height
        };
    }

    get url() {
        return this.gameApiModel.url;
    }

    get thumbnail() {
        return this.gameApiModel.thumbnailUrl; // this.gameApiModel.thumbnail
    }

    isPortrait() {
        const { width, height } = this.dimensions;
        return width < height;
    }
}

export class Game1 {
    constructor(
        id = 0,
        url = '',
        thumbnail = '',
        name = '',
        description = '',
        embededQuestions = false
    ) {
        this.id = id;
        this.url = url;
        this.thumbnail = thumbnail;
        this.name = name;
        this.description = description;
        this.embededQuestions = embededQuestions;
    }
}
