import React from 'react';
import { Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Marketplace from '../components/marketplace/Marketplace';
import SignInContainer from '../components/users/sigIn/SignInContainer';
import RegisterContainer from '../components/users/register/RegisterContainer';
import Youtube from '../components/youtube/Youtube';
import GamePlayerContainer from '../components/play/GamePlayerContainer';

const Routes = () => {
    return (
        <React.Fragment>
            <Route exact path="/" component={Marketplace} />
            <Route path="/login" component={SignInContainer} />
            <PrivateRoute path="/play" component={GamePlayerContainer} />
            <PrivateRoute path="/youtube" component={Youtube} />
            <Route path="/register" component={RegisterContainer} />
        </React.Fragment>
    );
};

export default Routes;
