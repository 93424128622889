import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { withNamespaces } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const styles = theme => ({
    button: {
        margin: theme.spacing.unit
    },
    actions: {
        margin: 0
    }
});

class Question extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { classes, open, question, onAnswer } = this.props;
        return (
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">
                    {' Math question?'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Typography variant="inherit" color="inherit">
                            {question && question.body}
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <Grid container>
                    {question &&
                        question.answers.map(answer => (
                            <Button
                                className={classes.button}
                                key={answer}
                                variant="contained"
                                onClick={() => onAnswer(answer)}
                                color="primary"
                            >
                                {answer}
                            </Button>
                        ))}
                </Grid>

                <DialogActions className={classes.actions}>
                    {/* question &&
                        question.answers.map(answer => (
                            <Button
                                className={classes.button}
                                key={answer}
                                variant="contained"
                                onClick={() => onAnswer(answer)}
                                color="primary"
                            >
                                {answer}
                            </Button>
                        )) */}
                </DialogActions>
            </Dialog>
        );
    }
}

Question.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Question);
