import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import PersonAdd from '@material-ui/icons/PersonAdd';
import Help from '@material-ui/icons/Help';
import { fontStyles } from '../../../styles/styles';
import AwaitableButton from '../../common/buttons/AwaitableButton';
import SignInGoogleButton from './SignInGoogleButton';

const styles = (theme) => ({
    font: fontStyles(theme),
    container: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: '5%',
        marginRight: '5%',
        marginTop: '10%',

        [theme.breakpoints.up(500)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        paddingTop: theme.spacing.unit,
    },
    paper: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#E8E8E8',
        borderRadius: 15,
        padding: theme.spacing.unit * 2.5,
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
    },
    textField: {
        marginTop: theme.spacing.unit * 1.5,
        marginBottom: theme.spacing.unit * 1.5,
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
    iconSmall: {
        fontSize: 20,
    },
    register: {
        width: '100%',
        paddingTop: theme.spacing.unit * 1.5,
        paddingBottom: theme.spacing.unit * 1.5,
    },
    social: {
        width: '100%',
        paddingTop: theme.spacing.unit * 2,
    },
    divider: {
        // marginTop: theme.spacing.unit * 1.5,
        marginBottom: theme.spacing.unit * 5,
    },
});

function SignIn(props) {
    const {
        t,
        classes,
        handleChange,
        handleSubmit,
        isSubmitting,
        errors,
    } = props;

    return (
        <div className={classes.container}>
            <Paper className={classes.paper} elevation={4}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography variant="h5" color="primary">
                    {t('_sign_in_.title')}
                </Typography>
                <form className={classes.form} onSubmit={handleSubmit}>
                    <TextField
                        id="username"
                        name="username"
                        label={t('_username_')}
                        helperText={t(errors.username) || null}
                        error={errors.username != null}
                        fullWidth
                        className={classes.textField}
                        onChange={handleChange}
                        autoFocus
                        autoComplete="username"
                        disabled={isSubmitting}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AccountCircle
                                        color={
                                            errors.username != null
                                                ? 'error'
                                                : 'primary'
                                        }
                                    />
                                </InputAdornment>
                            ),
                        }}
                    />

                    <TextField
                        type="password"
                        id="password"
                        name="password"
                        label={t('_password_')}
                        helperText={t(errors.password) || null}
                        error={errors.password != null}
                        fullWidth
                        autoComplete="current-password"
                        className={classes.textField}
                        onChange={handleChange}
                        disabled={isSubmitting}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LockOutlinedIcon
                                        color={
                                            errors.password != null
                                                ? 'error'
                                                : 'primary'
                                        }
                                    />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <AwaitableButton
                        type="submit"
                        fullWidth={true}
                        label={t('_sign_in_.label')}
                        isLoading={isSubmitting}
                    />
                </form>
                <div className={classes.register}>
                    <Button
                        className={classes.font}
                        variant="text"
                        size="small"
                        color="primary"
                        style={{ width: '40%', float: 'left' }}
                        replace
                        title={t('_register_.label')}
                        component={Link}
                        to="/register"
                    >
                        <PersonAdd
                            className={classNames(
                                classes.leftIcon,
                                classes.iconSmall
                            )}
                        />
                        {t('_register_.label')}
                    </Button>

                    <Button
                        className={classes.font}
                        variant="text"
                        size="small"
                        color="primary"
                        replace
                        style={{ float: 'right' }}
                        component={Link}
                        to="/forgotpassword"
                    >
                        <Help
                            className={classNames(
                                classes.leftIcon,
                                classes.iconSmall
                            )}
                        />
                        {t('_forgot_password_.label')}
                    </Button>
                </div>
                <Typography
                    variant="caption"
                    className={classNames.divider}
                    color="primary"
                >
                    {t('_or_')}
                </Typography>
                <div className={classes.social}>
                    <SignInGoogleButton fullWidth />
                </div>
            </Paper>
        </div>
    );
}

SignIn.propTypes = {
    classes: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
};

export default withTranslation()(withStyles(styles)(SignIn));
