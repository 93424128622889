import { SHOW_QUESTION } from '../actions/questions';

export default function questionsReducer(
    state = {
        question: null
    },
    action
) {
    switch (action.type) {
        case SHOW_QUESTION:
            return Object.assign({}, state, {
                question: action.question
            });
        default:
            return state;
    }
}
