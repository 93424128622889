import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles, withTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Avatar from '@material-ui/core/Avatar';
import { Link } from 'react-router-dom';
import VideoLibrary from '@material-ui/icons/VideoLibrary';
import Games from '@material-ui/icons/GamesTwoTone';
import GuestGreetings from './GuestGreetings';
import UserGreetings from './UserGreetings';

const styles = (theme) => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    avatar: {
        margin: theme.spacing.unit * 0.5,
        backgroundColor: theme.palette.common.white,
        width: 40,
        height: 40,
    },
    leftIcon: {
        marginRight: theme.spacing.unit * 0.5,
    },
    toolbar: {
        marginRight: 50,
        padding: 1,
    },
    menuButton: {
        [theme.breakpoints.down('sm')]: {
            fontSize: 11.5,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: 15,
        },
    },
});

function Header(props) {
    const { classes, currentUser } = props;
    return (
        <div id="header" className={classes.root}>
            <AppBar position="fixed">
                <Toolbar className={classes.toolbar}>
                    <Avatar
                        component={Link}
                        to="/"
                        src="assets/images/unnamed.png"
                        replace
                        className={classes.avatar}
                    />

                    <Button
                        color="inherit"
                        replace
                        size="small"
                        component={Link}
                        to="/youtube"
                        className={classes.menuButton}
                    >
                        <VideoLibrary className={classes.leftIcon} /> Youtube
                    </Button>
                    <Button
                        color="inherit"
                        replace
                        size="small"
                        component={Link}
                        to="/play"
                        className={classes.menuButton}
                    >
                        <Games className={classes.leftIcon} /> Play
                    </Button>

                    <div className={classes.grow} />
                    {!currentUser && <GuestGreetings />}
                    {currentUser && <UserGreetings />}
                </Toolbar>
            </AppBar>
        </div>
    );
}

Header.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        currentUser: state.auth.currentUser,
    };
};
export default connect(mapStateToProps)(withStyles(styles)(Header));
