import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const PrivateRoute = ({ component: Component, currentUser, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            currentUser ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: '/login',
                        state: {
                            from: props.location
                        }
                    }}
                />
            )
        }
    />
);

PrivateRoute.propTypes = {
    currentUser: PropTypes.object
};

const mapStateToProps = state => ({
    currentUser: state.auth.currentUser
});

export default connect(
    mapStateToProps,
    null,
    null,
    {
        pure: false //issue with redux render prop
    }
)(PrivateRoute);
