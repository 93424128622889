import { authService } from '../services/users/authenticationService';
import { showError } from './messages';

export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_FAILURE = 'SIGNIN_FAILURE';
export const SIGNIN_STARTED = 'SIGNIN_STARTED';

export function signIn(username, password) {
    return dispatch => {
        dispatch(signInStarted());
        authService
            .signIn(username, password)
            .then(response => {
                dispatch(signInSuccess(response));
            })
            .catch(error => {
                console.error(error);
                dispatch(signInFailed());
                dispatch(showError('_invalid_username_or_password_'));
            });
    };
}

function signInStarted() {
    return { type: SIGNIN_STARTED };
}
function signInSuccess(resp) {
    return { type: SIGNIN_SUCCESS, user: resp.user, token: resp.token };
}
function signInFailed() {
    return { type: SIGNIN_FAILURE };
}
