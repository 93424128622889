import MobileDetect from 'mobile-detect';

export const deviceUtils = {
    isPortrait,
    getWidth,
    geHeight,
    isRotatable,
    onOrientationChange,
    onOrientationChangeRemove
};

function isPortrait() {
    return window.matchMedia('(orientation: portrait)').matches;
}

function getWidth() {
    return window.innerWidth;
}

function geHeight() {
    return window.innerHeight;
}

function isRotatable() {
    //return window.screen.orientation !== undefined;
    const md = new MobileDetect(window.navigator.userAgent);
    return (md.phone() || md.tablet()) !== null;
}

function onOrientationChangeRemove(callback) {
    if (window.orientation !== undefined) {
        window.removeEventListener('orientationchange', callback, false);
    }
}

function onOrientationChange(callback) {
    if (window.orientation !== undefined) {
        window.addEventListener('orientationchange', callback, false);
    }
}
