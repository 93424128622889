import React from 'react';
import JssProvider from 'react-jss/lib/JssProvider';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { connect } from 'react-redux';

function Layout(props) {
    return (
        <MuiThemeProvider theme={props.currentTheme}>
            <JssProvider
                jss={props.jss}
                generateClassName={props.generateClassName}
            >
                {props.children}
            </JssProvider>
        </MuiThemeProvider>
    );
}

const mapStateToProps = state => {
    return {
        currentTheme: state.theme.currentTheme,
        jss: state.theme.jss,
        generateClassName: state.theme.generateClassName
    };
};

export default connect(mapStateToProps)(Layout);
