import { Game } from './game';
import { Question } from 'eddy-web-sdk';

export default class GameContext {
    constructor(apiModel) {
        this.currentGame = new Game(apiModel.game);
        this.currentQuestions = apiModel.questions.map(q => new Question(q));
    }
    get game() {
        return this.currentGame;
    }
    get questions() {
        return this.currentQuestions;
    }
}
