import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers/root';
import { authService } from '../services/users/authenticationService';

//const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const preloadedState = {
    auth: authService.getStoredUser()
};

export const store = createStore(
    rootReducer,
    preloadedState,
    applyMiddleware(thunk)
);
