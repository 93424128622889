import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import lngDetector from 'i18next-browser-languagedetector';
import english from './en/translations.json';
import russian from './ru/translations.json';
import hebrew from './he/translations.json';

const resources = {
    ru: {
        translation: russian
    },
    en: {
        translation: english
    },

    he: {
        translation: hebrew
    }
};

i18n.use(lngDetector)
    .use(initReactI18next)
    .init({
        resources,
        debug: true
        /*   fallbackLng: 'en',*/
    });

export default i18n;
