import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Avatar from '@material-ui/core/Avatar';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const styles = (theme) => ({
    popover: {
        padding: 10,
        minWidth: 200,
    },
    fab: {
        margin: theme.spacing.unit,
    },
});

class UserGreetings extends React.Component {
    state = {
        anchorEl: null,
    };

    handleClose = () => {
        this.setState({
            anchorEl: null,
        });
    };

    render() {
        const { classes } = this.props;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);

        return (
            <React.Fragment>
                <IconButton color="inherit">
                    <Badge>
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <Tooltip title="Eddy account">
                    <Avatar
                        alt="Eddy account"
                        src="/local/assets/images/gamer.jpg"
                        // onClick={this.handleClick}
                        // className={classes.greenAvatar}
                    />
                </Tooltip>

                <Popover
                    id="simple-popper"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <Grid container spacing={16} className={classes.popover}>
                        <Grid item xs={6} />
                        <Grid item xs={6}>
                            <Typography
                                variant="h6"
                                color="inherit"
                                className={classes.grow}
                            >
                                Eddy user
                            </Typography>
                            <Typography
                                variant="h5"
                                color="inherit"
                                className={classes.grow}
                            >
                                Eddy
                            </Typography>
                        </Grid>
                    </Grid>
                </Popover>
            </React.Fragment>
        );
    }
}
UserGreetings.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserGreetings);
