import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Fab from '@material-ui/core/Fab';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import { videosService } from '../../services/videos/videosService';

const styles = theme => ({
    heroUnit: {
        //backgroundColor: 'red'
    },
    heroContent: {
        maxWidth: 1100,
        margin: '0 auto',
        // backgroundColor: 'green'
        padding: theme.spacing.unit * 2
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
        [theme.breakpoints.up(1100 + theme.spacing.unit * 6)]: {
            width: 1100,
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    cardGrid: {
        padding: theme.spacing.unit
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        transitionDuration: '0.3s',
        '&:hover': {
            transform: 'scale(1.1)'
        }
        //borderRadius: 10
    },
    cardMedia: {
        paddingTop: '56.25%' // 16:9,
        // borderRadius: 15
    },
    cardContent: {
        flexGrow: 1
    }
});

class YoutubeSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = { videos: [] };
    }
    componentDidMount() {
        console.debug('YoutubeSearch componentDidMount');
        videosService
            .searchVideos('cartoon')
            .then(resp => {
                console.debug(resp);
                this.setState({ videos: resp });
            })
            .catch(err => {
                console.error(err);
            });
    }
    componentWillUnmount() {}

    render() {
        console.debug('YoutubeSearch render');
        const { classes } = this.props;
        return (
            <React.Fragment>
                <div className={classes.heroUnit}>
                    <div className={classes.heroContent}>
                        <TextField
                            id="search"
                            name="search"
                            placeholder="Search"
                            fullWidth
                        />
                    </div>
                </div>

                <div className={classNames(classes.layout, classes.cardGrid)}>
                    <Grid container spacing={40}>
                        {this.state.videos.map(video => (
                            <Grid
                                item
                                key={`video_${video.id}`}
                                sm={6}
                                md={4}
                                lg={3}
                                style={{ width: '100%' }}
                            >
                                <Card className={classes.card}>
                                    <CardMedia
                                        width={160}
                                        height={90}
                                        className={classes.cardMedia}
                                        image={video.thumbnail}
                                        title="Image title"
                                    />
                                    <CardContent
                                        className={classes.cardContent}
                                    >
                                        <Typography
                                            gutterBottom
                                            variant="h6"
                                            component="h2"
                                        >
                                            {video.name}
                                        </Typography>
                                        <Typography>
                                            {video.description}
                                        </Typography>
                                    </CardContent>
                                    <CardActions
                                        style={{ justifyContent: 'center' }}
                                    >
                                        <Fab color="primary" aria-label="Play">
                                            <PlayArrowIcon />
                                        </Fab>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </React.Fragment>
        );
    }
}

YoutubeSearch.propTypes = {
    // classes: PropTypes.object.isRequired
};
export default withStyles(styles)(YoutubeSearch);
