import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Games from '@material-ui/icons/PersonOutline';
import Button from '@material-ui/core/Button';
import LanguageSelector from './LanguageSelector';
const styles = theme => ({
    rightIcon: {
        marginLeft: theme.spacing.unit * 0.5,
        fontSize: 30
    },
    menuButton: {
        [theme.breakpoints.down('sm')]: {
            fontSize: 11.5
        },
        [theme.breakpoints.up('md')]: {
            fontSize: 15
        }
    }
});

function GuestGreetings(props) {
    const { classes } = props;
    return (
        <React.Fragment>
            <Button
                color="inherit"
                replace
                size="small"
                component={Link}
                to="/login"
                className={classes.menuButton}
            >
                <Games className={classes.rightIcon} /> Sign In
            </Button>

            <LanguageSelector />
        </React.Fragment>
    );
}

GuestGreetings.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(GuestGreetings);
