import axios from 'axios';
import { config } from '../services/config';

export function initAxios(store) {
    axios.defaults.baseURL = config.baseUrl;

    //const token = window.localStorage.getItem(config.tokenHeader);

    //if (token) {
    //   axios.defaults.headers.common[config.tokenHeader] = token;
    //}

    axios.interceptors.request.use(function (req) {
        const token = store.getState().auth.token;
        window.localStorage.setItem('access_token', token);

        if (token !== null) req.headers.common[config.tokenHeader] = token;
        req.headers.common['Access-Control-Allow-Origin'] = '*';

        return req;
    });
}
