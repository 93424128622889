import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';
import { hideMessage } from '../../actions/messages';

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon
};

const styles = theme => ({
    success: {
        backgroundColor: green[600]
    },
    error: {
        backgroundColor: theme.palette.error.dark
    },
    info: {
        backgroundColor: theme.palette.primary.dark
    },
    warning: {
        backgroundColor: amber[700]
    },
    icon: {
        fontSize: 20
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing.unit
    },
    message: {
        display: 'flex',
        alignItems: 'center'
    },
    margin: {
        margin: theme.spacing.unit
    }
});

function SnackbarAlert(props) {
    const {
        open,
        hide,
        text,
        classes,
        className,
        message,
        onClose,
        variant,
        ...other
    } = props;
    const Icon = variantIcon[variant || 'info'];
    const customHide = (event, reason) => {
        if (reason && reason === 'clickaway') return;
        hide();
    };
    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                open={open}
                autoHideDuration={5000}
                onClose={customHide}
            >
                <SnackbarContent
                    className={classNames(classes[variant], className)}
                    aria-describedby="client-snackbar"
                    message={
                        <span id="client-snackbar" className={classes.message}>
                            <Icon
                                className={classNames(
                                    classes.icon,
                                    classes.iconVariant
                                )}
                            />
                            {text}
                        </span>
                    }
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className={classes.close}
                            onClick={customHide}
                        >
                            <CloseIcon className={classes.icon} />
                        </IconButton>
                    ]}
                    {...other}
                />
            </Snackbar>
        </div>
    );
}

SnackbarAlert.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
        open: state.messages.messageData.open,
        variant: state.messages.messageData.variant,
        text: state.messages.messageData.text
    };
};

const mapDispatchToProps = dispatch => {
    return {
        hide: () => dispatch(hideMessage())
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(SnackbarAlert));
