import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';

import { useTranslation } from 'react-i18next';

const styles = theme => ({
    button: {
        background: '#e14441',
        color: 'white',
        borderRadius: theme.spacing.unit * 5,
        '&:hover': {
            background: '#C83F3D'
        }
    },
    text: {
        paddingLeft: theme.spacing.unit
    }
});

function SignInGoogleButton(props) {
    const { classes, ...rest } = props;
    const { t } = useTranslation();
    return (
        <Button
            title={t('_login_with_google_.label')}
            variant="contained"
            className={classes.button}
            {...rest}
        >
            <span className="fa fa-google fa-lg" />
            <span className={classes.text}>
                {t('_login_with_google_.label')}
            </span>
        </Button>
    );
}

SignInGoogleButton.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SignInGoogleButton);
