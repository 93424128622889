import { combineReducers } from 'redux';
import messagesReducer from './messages';
import authenticationReducer from './authentication';
import themeReducer from './theme';
import questionsReducer from './questions';

const rootReducer = combineReducers({
    messages: messagesReducer,
    auth: authenticationReducer,
    theme: themeReducer,
    questions: questionsReducer
});

export default rootReducer;
