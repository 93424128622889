import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import YouTubePlayer from 'react-youtube';
import { withQuestions } from '../hoc/WithQuestions';
import YoutubeSearch from './YoutubeSearch';
//import './Youtube.css';

const styles = () => ({
    wrapper: {},

    player: {}
});

class Youtube extends React.Component {
    constructor(props) {
        super(props);
        this.addEmbededCssClass = this.addEmbededCssClass.bind(this);
        this.fullScreenMode = this.fullScreenMode.bind(this);
        this.onReady = this.onReady.bind(this);
        this.player = null;
    }
    componentDidMount() {
        if (window.orientation !== undefined) {
            window.addEventListener('orientationchange', this.fullScreenMode, {
                useCapture: false
            });
            this.fullScreenMode();
        }
    }
    componentWillUnmount() {
        if (window.orientation !== undefined) {
            window.removeEventListener(
                'orientationchange',
                this.fullScreenMode
            );
        }
    }

    addEmbededCssClass() {
        const iframe = document.getElementById('player');
        const height = window.innerHeight;
        const width = window.innerWidth;

        const ratio =
            width > height ? (height / width) * 100 : (width / height) * 100;

        if (document.getElementById('embeded_custom') === null) {
            const styleElement = document.createElement('style');
            var textNode = document.createTextNode(
                `.embeded_custom::before { padding-top: ${ratio}%;}`
            );
            styleElement.setAttribute('id', 'embeded_custom');
            styleElement.appendChild(textNode);
            iframe.appendChild(styleElement);
        }
    }

    fullScreenMode() {
        this.addEmbededCssClass();
        const main = document.getElementById('main');
        const header = document.getElementById('header');
        const iframe = document.getElementById('player');

        if (Math.abs(window.orientation) === 90) {
            main.data = main.style.marginTop;
            main.style.marginTop = '0px';
            header.style.display = 'none';
            iframe.className = 'embed-responsive embeded_custom';
        } else {
            if (window.orientation !== undefined)
                iframe.className = 'embed-responsive embed-responsive-16by9';
            else iframe.className = 'embed-responsive embed-responsive-21by9';
            main.style.marginTop = main.data;
            header.style.display = '';
        }
    }

    onReady(e) {
        this.player = e.target;
        this.play = false;
    }
    render() {
        const { onPlay, onEnd, onPause, shouldPause } = this.props;

        if (this.player && this.play)
            shouldPause ? this.player.pauseVideo() : this.player.playVideo();

        return (
            <React.Fragment>
                <div
                    id="player"
                    className="embed-responsive embed-responsive-21by9"
                >
                    <YouTubePlayer
                        className="embed-responsive-item"
                        onPlay={() => {
                            this.play = true;
                            onPlay();
                        }}
                        onEnd={onEnd}
                        onReady={this.onReady}
                        onPause={onPause}
                        videoId="gFk3tPRVi4Q"
                    />
                </div>
                <YoutubeSearch />
            </React.Fragment>
        );
    }
}

Youtube.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withQuestions(withStyles(styles)(Youtube));
