import axios from 'axios';
import { User } from '../../models/user';
import { config } from '../config';

export const authService = {
    signIn,
    getStoredUser,
};

function getStoredUser() {
    if (window.cordova)
        return {
            currentUser: null,
            token: null,
            isProcessing: false,
        };
    else
        return {
            currentUser: null,
            token: null,
            isProcessing: false,
        };
}

function signIn(username, password) {
    return axios
        .post('/api/login', {
            username: username,
            password: password,
        })
        .then(function (response) {
            const token = response.headers[config.tokenHeader];
            const user = new User(
                response.data.username,
                response.data.username,
                response.data.language
            );
            return {
                user: user,
                token: token,
            };
        });
}
