import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { GamesService } from '../../services/games/gamesService';
import MarketplaceImage from './assets/Marketplace.jpg';

const styles = (theme) => ({
    heroRoot: {
        backgroundImage: `url(${MarketplaceImage})`,
        backgroundSize: 'cover',
    },

    layout: {
        width: 'auto',
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
        [theme.breakpoints.up(1100 + theme.spacing.unit * 6)]: {
            width: 1100,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    cardGrid: {
        paddingTop: 120,
        padding: theme.spacing.unit,
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        transitionDuration: '0.3s',
        '&:hover': {
            transform: 'scale(1.1)',
        },
        textDecoration: 'none',
        //backgroundColor: theme.palette.primary.main,
        border: `2px solid ${theme.palette.primary.main}`,
        boxShadow: `10px 10px 50px 15px ${theme.palette.primary.main}`,

        borderRadius: 10,
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9,
        // borderRadius: 15
    },
    cardContent: {
        flexGrow: 1,
        color: 'white',
    },
});

class Marketplace extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            games: [],
        };
    }

    async componentDidMount() {
        const games = await GamesService.getAllGames();

        this.setState({ games: games });
    }

    render() {
        const { classes } = this.props;
        const { games } = this.state;

        return (
            <React.Fragment>
                <div className={classes.heroRoot}>
                    <div
                        className={classNames(classes.layout, classes.cardGrid)}
                    >
                        <Grid container spacing={40}>
                            {games.map((game) => (
                                <Grid
                                    item
                                    key={`game_${game.id}`}
                                    sm={6}
                                    md={4}
                                    lg={3}
                                    style={{ width: '100%' }}
                                >
                                    <Card
                                        className={classes.card}
                                        component={Link}
                                        to={{
                                            pathname: '/play',
                                            state: { gameId: game.id },
                                        }}
                                    >
                                        <CardMedia
                                            width={160}
                                            height={90}
                                            className={classes.cardMedia}
                                            image={game.thumbnail}
                                            title="Image title"
                                        />
                                        <CardContent
                                            className={classes.cardContent}
                                        >
                                            <Typography
                                                gutterBottom
                                                variant="h5"
                                                component="h2"
                                            >
                                                {game.name}
                                            </Typography>
                                            <Typography>
                                                {game.description}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

Marketplace.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Marketplace);
