import { createMuiTheme } from '@material-ui/core/styles';

export const THEME_CHANGED = 'THEME_CHANGED';

export function changeThemeDirecton(direction) {
    return {
        type: THEME_CHANGED,
        currentTheme: createMuiTheme({
            direction: direction,
            typography: {
                useNextVariants: true
            }
        })
    };
}
