import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { withStyles } from '@material-ui/core/styles';
import Language from '@material-ui/icons/Language';
import { changeThemeDirecton } from '../../actions/theme';
import i18n from '../../i18n/i18n';

const styles = theme => ({
    root: {
        display: 'flex'
    },
    button: {
        minWidth: 15
    }
});

const supportedLanguages = [
    {
        name: 'English',
        direction: 'ltr',
        locale: 'en'
    },
    {
        name: 'עברית',
        direction: 'rtl',
        locale: 'he'
    },
    {
        name: 'Русский',
        direction: 'ltr',
        locale: 'ru'
    }
];

class LanguageSelector extends React.Component {
    state = {
        open: false
    };

    handleToggle = () => {
        this.setState(state => ({ open: !state.open }));
    };

    handleClose = event => {
        if (this.anchorEl.contains(event.target)) {
            return;
        }

        this.setState({ open: false });
    };

    handleMenuClick = language => {
        this.setState({ open: false });
        document.getElementById('body').dir = language.direction;

        i18n.changeLanguage(language.locale);
        this.props.changeThemeDirecton(language.direction);
    };

    render() {
        const { classes } = this.props;
        const { open } = this.state;

        return (
            <div className={classes.root}>
                <Button
                    color="inherit"
                    size="small"
                    className={classes.button}
                    buttonRef={node => {
                        this.anchorEl = node;
                    }}
                    aria-owns={open ? 'languageMenuGrow' : undefined}
                    aria-haspopup="true"
                    onClick={this.handleToggle}
                >
                    <Language />
                </Button>
                <Popper
                    open={open}
                    anchorEl={this.anchorEl}
                    transition
                    disablePortal
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            id="languageMenuGrow"
                            style={{
                                transformOrigin:
                                    placement === 'bottom'
                                        ? 'center top'
                                        : 'center bottom'
                            }}
                        >
                            <Paper>
                                <ClickAwayListener
                                    onClickAway={this.handleClose}
                                >
                                    <MenuList>
                                        {supportedLanguages.map(language => (
                                            <MenuItem
                                                key={language.name}
                                                onClick={this.handleMenuClick.bind(
                                                    this,
                                                    language
                                                )}
                                            >
                                                {language.name}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        );
    }
}

LanguageSelector.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => {
    return {
        changeThemeDirecton: direction =>
            dispatch(changeThemeDirecton(direction))
    };
};
export default connect(
    null,
    mapDispatchToProps
)(withStyles(styles)(LanguageSelector));
