import React from 'react';

import Question from '../questions/Question';
import { questionService } from '../../services/questions/questionsService';

export const withQuestions = WrappedComponent => {
    class WithQuestionsHOC extends React.Component {
        constructor(props) {
            super(props);
            this.playPaused = false;

            this.state = {
                showQuestion: false,
                question: null,
                shouldPause: false
            };

            this.onPlay = this.onPlay.bind(this);
            this.onEnd = this.onEnd.bind(this);
            this.onPause = this.onPause.bind(this);
            this.onAnswer = this.onAnswer.bind(this);
            this.getQuestion = this.getQuestion.bind(this);
        }

        onPlay() {
            console.debug('onPlay');
            this.playPaused = false;
            setTimeout(this.getQuestion, 5000);
        }

        getQuestion() {
            questionService.getQuestion().then(q => {
                console.debug('getQuestion');
                if (q !== null && !this.playPaused)
                    this.setState({
                        showQuestion: true,
                        question: q,
                        shouldPause: true
                    });
            });
        }

        onEnd() {
            this.playPaused = true;
        }

        onPause() {
            this.playPaused = true;
        }

        onAnswer(answer) {
            this.setState({
                showQuestion: false,
                question: null,
                shouldPause: false
            });
        }

        render() {
            return (
                <React.Fragment>
                    <WrappedComponent
                        {...this.props}
                        onPlay={this.onPlay}
                        onEnd={this.onEnd}
                        onPause={this.onPause}
                        shouldPause={this.state.shouldPause}
                    />
                    <Question
                        open={this.state.showQuestion}
                        onAnswer={this.onAnswer}
                        question={this.state.question}
                    />
                </React.Fragment>
            );
        }
    }

    return WithQuestionsHOC;
};
