import axios from 'axios';
import { Game } from '../../models/game';
import GameContext from '../../models/gameContext';

class GamesService {
    createRandomGameContext(gameid = -1) {
        return axios
            .get(`/api/play${gameid === -1 ? '' : '?gameid=' + gameid}`)
            .then((resp) => {
                return new GameContext(resp.data);
            })
            .catch((err) => {
                console.log(
                    `Games service 'createRandomGameContext' error ${err}`
                );
                throw err;
            });
    }

    getAllGames(page = 1) {
        return axios
            .get(`/api/game/public?page=${page}`)
            .then((resp) => {
                return resp.data.map((gameApiModel) => new Game(gameApiModel));
            })
            .catch((err) => {
                console.log(`Games service 'getAllGames' error ${err}`);
                throw err;
            });
    }
}

const instance = new GamesService();

export { instance as GamesService };
