import React from 'react';
import PropTypes from 'prop-types';
import { HashRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';
import Header from './layout/Header';
import SnackbarAlert from './layout/SnackbarAlert';
import Layout from './layout/Layout';
import Routes from '../router/Routes';
import { EddySdk } from 'eddy-web-sdk';

const styles = theme => ({
    main: {
        // minHeight: '480px',
        // marginTop: theme.spacing.unit * 8,
        /*[theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing.unit * 7
        },
        [theme.breakpoints.up('md')]: {
            // marginTop: theme.spacing.unit * 8,
            //   marginBottom: theme.spacing.unit * -8
        },
        [theme.breakpoints.up('lg')]: {
            // marginTop: theme.spacing.unit * 8,
            //  marginBottom: theme.spacing.unit * -8
        },*/
        backgroundColor: 'red',
        padding: 0,
        height: 0
    }
});

function App(props) {
    const { classes } = props;
    return (
        <Layout>
            <HashRouter>
                <React.Fragment>
                    <CssBaseline />
                    <Header />
                    <div id="main" className={classes.main}>
                        <Routes />
                    </div>
                    {/*<Footer />*/}
                    <SnackbarAlert />
                </React.Fragment>
            </HashRouter>
        </Layout>
    );
}

window.EddySdk = EddySdk;
App.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(App);
