import React, { Component } from 'react';
import { Formik } from 'formik';
import Register from './Register';

class RegisterContainer extends Component {
    constructor(props) {
        super(props);

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleFormValidate = this.handleFormValidate.bind(this);
    }

    handleFormValidate = values => {
        const { t } = this.props;
        let errors = {};

        if (!values.firstname) {
            errors.firstname = '_required_field_';
        }
        if (!values.lastname) {
            errors.lastname = '_required_field_';
        }
        if (!values.email) {
            errors.email = '_required_field_';
        }
        if (!values.password) {
            errors.password = '_required_field_';
        }
        return errors;
    };

    handleFormSubmit = async (values, actions) => {
        await new Promise(resolve =>
            setTimeout(
                () =>
                    resolve({
                        isError: true
                    }),
                1000
            )
        );

        this.props.history.push('/login');
    };

    render() {
        return (
            <Formik
                validate={this.handleFormValidate}
                validateOnBlur={true}
                validateOnChange={true}
                onSubmit={this.handleFormSubmit}
                render={props => <Register {...props} />}
            />
        );
    }
}

export default RegisterContainer;
