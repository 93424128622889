import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { signIn } from '../../../actions/authentication';
import SignIn from './SignIn';

class SignInContainer extends Component {
    constructor(props) {
        super(props);

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleFormValidate = this.handleFormValidate.bind(this);
    }

    handleFormValidate = formValues => {
        let errors = {};

        if (!formValues.username) {
            errors.username = '_required_field_';
        }
        if (!formValues.password) {
            errors.password = '_required_field_';
        }
        return errors;
    };

    handleFormSubmit = formValues => {
        this.props.signIn(formValues.username, formValues.password);
    };

    render() {
        const { isProcessing, currentUser } = this.props;
        const { from } = this.props.location.state || {
            from: { pathname: '/' }
        };
        return (
            <React.Fragment>
                {currentUser && <Redirect to={from} />}

                {!currentUser && (
                    <Formik
                        validate={this.handleFormValidate}
                        validateOnBlur={true}
                        validateOnChange={true}
                        onSubmit={this.handleFormSubmit}
                        render={props => (
                            <SignIn {...props} isSubmitting={isProcessing} />
                        )}
                    />
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isProcessing: state.auth.isProcessing
    };
};

const mapDispatchToProps = {
    signIn
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SignInContainer);
