import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({});

function AwaitableButton(props) {
    const { classes, isLoading, label, ...rest } = props;

    return (
        <Button
            title={label}
            disabled={isLoading}
            variant="contained"
            color="primary"
            {...rest}
        >
            {!isLoading && label}
            {isLoading && <CircularProgress size={24} />}
        </Button>
    );
}

AwaitableButton.propTypes = {
    classes: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired
};

export default withStyles(styles)(AwaitableButton);
