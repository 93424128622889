export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'SHOW_MESSAGE';

export function showError(term) {
    return showMessage(term, 'error');
}

function showMessage(text, variant) {
    return {
        type: SHOW_MESSAGE,
        messageData: {
            open: true,
            variant: variant,
            text: text
        }
    };
}

export function hideMessage() {
    return {
        type: SHOW_MESSAGE,
        messageData: {
            open: false,
            variant: null,
            text: null
        }
    };
}
