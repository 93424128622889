import {
    SIGNIN_SUCCESS,
    SIGNIN_STARTED,
    SIGNIN_FAILURE
} from '../actions/authentication';

export default function authenticationReducer(
    state = {
        currentUser: null,
        isProcessing: false,
        token: null
    },
    action
) {
    switch (action.type) {
        case SIGNIN_STARTED:
            return {
                ...state,
                isProcessing: true,
                currentUser: null,
                token: null
            };
        case SIGNIN_SUCCESS:
            return {
                ...state,
                currentUser: action.user,
                token: action.token
            };
        case SIGNIN_FAILURE:
            return {
                ...state,
                isProcessing: false,
                currentUser: null,
                token: null
            };
        default:
            return state;
    }
}
