import axios from 'axios';
import { Video } from '../../models/video';

export const videosService = {
    searchVideos,
};

function searchVideos(searchText) {
    return axios
        .get('https://www.googleapis.com/youtube/v3/search', {
            params: {
                q: searchText,
                key: 'AIzaSyBJlPy8PTX1q7w52g81eBIxyQ3dMAJp-us'
                // code: 'codeisfun'
            },
            headers: {
                'Access-Control-Allow-Origin': 'https://eddygames.net/',
                'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
                'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token, Authorization'
            }
        })
        .then((resp) => {
            var videos = resp.data.map((video) => {
                return new Video(
                    video.id,
                    video.title,
                    video.thumbnails.high.url,
                    video.link,
                    video.description
                );
            });
            console.debug(resp.data);
            return videos;
        })
        .catch((err) => {
            console.error(err);
            return null;
            //throw Error('Failed to get questions');
        });
}
