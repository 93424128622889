import { SHOW_MESSAGE, HIDE_MESSAGE } from '../actions/messages';

export default function messagesReducer(
    state = {
        messageData: {
            open: false,
            variant: null,
            text: null
        }
    },
    action
) {
    switch (action.type) {
        case SHOW_MESSAGE:
            return Object.assign({}, state, {
                messageData: action.messageData
            });
        case HIDE_MESSAGE:
            return Object.assign({}, state, {
                messageData: action.messageData
            });
        default:
            return state;
    }
}
