import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
//import 'typeface-roboto';
import './i18n/i18n';
import 'font-awesome/css/font-awesome.css';
import { store } from './stores/store';
import { initAxios } from './utils/apiAgent';
import App from './components/App';

const startApp = () => {
    //android keyboard issue
    if (window.cordova) window.AndroidFullScreen.immersiveMode();

    initAxios(store);

    ReactDOM.render(
        <Provider store={store}>
            <App />
        </Provider>,
        document.getElementById('root')
    );
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: http://bit.ly/CRA-PWA
    serviceWorker.unregister();
};

if (window.cordova) {
    document.addEventListener('deviceready', startApp, false);
} else {
    startApp();
}
