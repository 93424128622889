import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import ScreenRotation from '@material-ui/icons/ScreenRotation';
import { deviceUtils } from '../../utils/deviceUtils';

const styles = theme => ({
    progress: {
        margin: theme.spacing.unit * 2
    },
    rotateWrapper: {
        position: 'absolute',
        marginLeft: 'auto',
        marginRight: 'auto',
        left: 0,
        right: 0,
        top: 0,
        textAlign: 'center',
        paddingTop: '40vh'
        // zIndex: 10000
    },

    rotateIcon: {
        fontSize: '20vh'
    }
});

class GamePlayer extends React.Component {
    constructor(props) {
        super(props);

        this.handleOrientationChange = this.handleOrientationChange.bind(this);
        this.createStateObject = this.createStateObject.bind(this);
        this.gameIframe = null;
        this.playerBorderCss = `border-radius:15px; box-shadow: 10px
        10px 40px 0px rgba(24,12,242,1);`;

        this.state = this.initGameComponent();
    }

    componentDidMount() {
        this.props.onGameComponentReady(this.gameIframe);
        deviceUtils.onOrientationChange(this.handleOrientationChange);
    }

    componentWillUnmount() {
        deviceUtils.onOrientationChangeRemove(this.handleOrientationChange);
    }

    initGameComponent() {
        const isRotatable = deviceUtils.isRotatable();

        if (isRotatable) {
            return this.initGameComponentOnRotatableDevice();
        } else {
            return this.initGameComponentOnNonRotatableDevice();
        }
    }

    handleOrientationChange(e) {
        const { fullScreen } = this.state;
        if (fullScreen) {
            if (Math.abs(e.target.orientation) === 90) {
                console.debug(`landscape`);
                this.toogleHeader(this.props.gameContext.game.isPortrait());
            } else {
                console.debug(`portrait`);
                this.toogleHeader(!this.props.gameContext.game.isPortrait());
            }
        }
        this.setState(prevState => ({
            isRotationRequired: !prevState.isRotationRequired,
            gamePlayerContainer: {
                ...prevState.gamePlayerContainer,
                border:
                    fullScreen || !prevState.isRotationRequired
                        ? ''
                        : this.playerBorderCss
            },
            player: {
                styles: {
                    ...prevState.player.styles,
                    opacity: !prevState.isRotationRequired ? 0 : 1
                }
            }
        }));
    }

    createStateObject(
        playerContainerWidth,
        playerContainerHeight,
        playerContainerPaddingTop,
        playerContainerBorder,
        playerWidth,
        playerHeight,
        playerOpacity,
        fullscreen,
        isRotationRequired
    ) {
        return {
            fullScreen: fullscreen,
            isRotationRequired: isRotationRequired,
            gamePlayerContainer: {
                width: playerContainerWidth,
                height: playerContainerHeight,
                paddingTop: playerContainerPaddingTop,
                border: playerContainerBorder
            },
            player: {
                styles: {
                    width: playerWidth,
                    height: playerHeight,
                    opacity: playerOpacity
                }
            }
        };
    }

    initGameComponentOnNonRotatableDevice() {
        console.debug('initGameComponentOnNonRotatableDevice');
        const game = this.props.gameContext.game;
        let gameWidth = game.dimensions.width;
        let gameHeight = game.dimensions.height;
        const screenWidth = deviceUtils.getWidth();
        const screenHeight = deviceUtils.geHeight() - 64; //64 is nav bar

        if (gameHeight > screenHeight) {
            let ratio = screenHeight / gameHeight;
            gameHeight = screenHeight;
            gameWidth = gameWidth * ratio;
        }

        return this.createStateObject(
            `${gameWidth}px`,
            `${gameHeight}px`,
            `calc(((100vh - ${gameHeight}px) / 2 ) + 32px)`,
            this.playerBorderCss,
            `${gameWidth}px`,
            `${gameHeight}px`,
            1,
            false,
            false
        );
    }

    initGameComponentOnRotatableDevice() {
        const deviceIsPortrait = deviceUtils.isPortrait();
        const gameIsPortrait = this.props.gameContext.game.isPortrait();

        if (
            (gameIsPortrait && deviceIsPortrait) ||
            (!gameIsPortrait && !deviceIsPortrait)
        ) {
            if (this.isFullScreenGame(true)) {
                return this.initFullScreenGame();
            } else {
                return this.initNonFullScreenGame();
            }
        } else {
            if (this.isFullScreenGame(false))
                return this.initFullScreenGameOnWrongRotation();
            else return this.initNonFullScreenGameOnWrongRotation();
        }
    }

    initFullScreenGame() {
        const screenWidth = deviceUtils.getWidth();
        const screenHeight = deviceUtils.geHeight();
        this.toogleHeader(false);
        return this.createStateObject(
            '100%',
            '100%',
            '0px',
            '',
            `${screenWidth}px`,
            `${screenHeight}px`,
            1,
            true,
            false
        );
    }

    initFullScreenGameOnWrongRotation() {
        console.debug(
            window.screen.height,
            window.screen.availHeight,
            window.innerHeight,
            window.outerHeight
        );
        const screenWidth = deviceUtils.getWidth();
        const screenHeight = deviceUtils.geHeight();

        return this.createStateObject(
            `${screenHeight}px`,
            `${screenWidth}px`,
            `0px`,
            '',
            `${screenHeight}px`,
            `${screenWidth}px`,
            0,
            true,
            true
        );
    }

    initNonFullScreenGame() {
        const game = this.props.gameContext.game;
        const gameWidth = game.dimensions.width;
        const gameHeight = game.dimensions.height;
        return this.createStateObject(
            `${gameWidth}px`,
            `${gameHeight}px`,
            `calc(((100vh - ${gameHeight}px) / 2 ) + 32px)`,
            this.playerBorderCss,
            `${gameWidth}px`,
            `${gameHeight}px`,
            1,
            false,
            false
        );
    }

    initNonFullScreenGameOnWrongRotation() {
        const game = this.props.gameContext.game;
        const gameWidth = game.dimensions.width;
        const gameHeight = game.dimensions.height;
        return this.createStateObject(
            `${gameWidth}px`,
            `${gameHeight}px`,
            `calc(((100vh - ${gameHeight}px) / 2 ) + 32px)`,
            '',
            `${gameWidth}px`,
            `${gameHeight}px`,
            0,
            false,
            true
        );
    }

    isFullScreenGame(rightRotation) {
        const game = this.props.gameContext.game;
        const gameWidth = game.dimensions.width;
        const gameHeight = game.dimensions.height;
        const screenWidth = deviceUtils.getWidth();
        const screenHeight = deviceUtils.geHeight();

        return rightRotation
            ? gameWidth > screenWidth || gameHeight > screenHeight
            : gameWidth > screenHeight || gameHeight > screenWidth;
    }

    toogleHeader(show) {
        const main = document.getElementById('main');
        const header = document.getElementById('header');
        if (!show) {
            main.data = main.style.marginTop;
            main.style.marginTop = '0px';
            header.style.display = 'none';
        } else {
            main.style.marginTop = main.data;
            header.style.display = '';
        }
    }

    renderCss() {
        const { embededCss, gamePlayerContainer } = this.state;
        const { gameContext } = this.props;

        return (
            <style>
                body {'{'} overflow: hidden; {'}'}
                .embed-responsive {'{'}
                position: relative; display: block; width:
                {gamePlayerContainer.width};height:{gamePlayerContainer.height};
                padding: 0; overflow: hidden; text-align: center; margin-left:
                auto; margin-right: auto; {gamePlayerContainer.border};{'}'}
                .embeded_custom::before {'{'}
                display: block; content: '';
                {'}'}
                .embed-responsive .embed-responsive-item, .embed-responsive
                iframe {'{'}
                position: absolute; top: 0; bottom: 0; left: 0; width: 100%;
                height: 100%; border: 0;border-radius:15px;
                {'}'}
                .root {'{'}
                {`padding-top:${gamePlayerContainer.paddingTop};`}
                height: 100vh; background-color: #cccccc; background-image:
                url("assets/images/background.jpg"); background-size:100% 100%;
                {'}'}
            </style>
        );
    }

    renderIcons() {
        const { classes, gameReady } = this.props;
        const { isRotationRequired } = this.state;

        if (isRotationRequired) {
            return (
                <div className={classes.rotateWrapper}>
                    <ScreenRotation
                        className={classes.rotateIcon}
                        fontSize="large"
                        color="primary"
                    />
                </div>
            );
        } else if (!gameReady) {
            return (
                <div className={classes.rotateWrapper}>
                    {!gameReady && <CircularProgress size={80} />}
                </div>
            );
        }
    }

    render() {
        const { player } = this.state;
        const { gameContext, classes, gameReady } = this.props;

        return (
            <React.Fragment>
                {this.renderCss()}

                <div className={'root'}>
                    {this.renderIcons()}

                    <div id="g" className={'embed-responsive embeded_custom'}>
                        <iframe
                            ref={iframe => {
                                this.gameIframe = iframe;
                            }}
                            scrolling="no"
                            className={'embed-responsive-item'}
                            style={player.styles}
                            src={gameContext.game ? gameContext.game.url : ''}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

GamePlayer.propTypes = {
    classes: PropTypes.object.isRequired,
    gameContext: PropTypes.object.isRequired,
    onGameComponentReady: PropTypes.func.isRequired,
    gameReady: PropTypes.bool.isRequired
};

export default withStyles(styles)(GamePlayer);
