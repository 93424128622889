import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Email from '@material-ui/icons/Email';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AccountCircleOutlined from '@material-ui/icons/AccountCircleOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withTranslation } from 'react-i18next';

const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    paper: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit *
            3}px ${theme.spacing.unit * 3}px`
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.primary.main
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit
    },
    submit: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2
    },
    textField: {
        marginTop: theme.spacing.unit * 1.5,
        marginBottom: theme.spacing.unit * 1.5
    }
});

function Register(props) {
    const {
        t,
        classes,
        handleChange,
        handleSubmit,
        isSubmitting,
        errors
    } = props;

    return (
        <div className={classes.main}>
            <Paper className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <AccountCircle />
                </Avatar>
                <Typography component="div" variant="h5">
                    {t('_register_')}
                </Typography>
                <form className={classes.form} onSubmit={handleSubmit}>
                    <TextField
                        id="firstname"
                        name="firstname"
                        label={t('_firstname_')}
                        helperText={errors.firstname || ' '}
                        error={errors.firstname != null}
                        fullWidth
                        className={classes.textField}
                        onChange={handleChange}
                        autoFocus
                        autoComplete="firstname"
                        disabled={isSubmitting}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AccountCircleOutlined
                                        color={
                                            errors.firstname != null
                                                ? 'error'
                                                : 'primary'
                                        }
                                    />
                                </InputAdornment>
                            )
                        }}
                    />

                    <TextField
                        id="lastname"
                        name="lastname"
                        label={t('_lastname_')}
                        helperText={errors.lastname || ' '}
                        error={errors.lastname != null}
                        fullWidth
                        className={classes.textField}
                        onChange={handleChange}
                        autoComplete="lastname"
                        disabled={isSubmitting}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AccountCircle
                                        disabled={isSubmitting}
                                        color={
                                            errors.lastname != null
                                                ? 'error'
                                                : 'primary'
                                        }
                                    />
                                </InputAdornment>
                            )
                        }}
                    />

                    <TextField
                        id="email"
                        name="email"
                        label={t('_email_')}
                        helperText={errors.email || ' '}
                        error={errors.email != null}
                        fullWidth
                        className={classes.textField}
                        onChange={handleChange}
                        autoComplete="email"
                        disabled={isSubmitting}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Email
                                        disabled={isSubmitting}
                                        color={
                                            errors.email != null
                                                ? 'error'
                                                : 'primary'
                                        }
                                    />
                                </InputAdornment>
                            )
                        }}
                    />
                    <TextField
                        type="password"
                        id="password"
                        name="password"
                        label={t('_password_')}
                        helperText={errors.password || ' '}
                        error={errors.password != null}
                        fullWidth
                        autoComplete="current-password"
                        className={classes.textField}
                        onChange={handleChange}
                        disabled={isSubmitting}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LockOutlinedIcon
                                        color={
                                            errors.password != null
                                                ? 'error'
                                                : 'primary'
                                        }
                                    />
                                </InputAdornment>
                            )
                        }}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={isSubmitting}
                    >
                        {!isSubmitting && t('_register_')}
                        {isSubmitting && <CircularProgress size={24} />}
                    </Button>
                </form>
            </Paper>
        </div>
    );
}

Register.propTypes = {
    classes: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired
};

export default withTranslation()(withStyles(styles)(Register));
