import { create } from 'jss';
import rtl from 'jss-rtl';
import { createGenerateClassName, jssPreset } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { THEME_CHANGED } from '../actions/theme';

export default function themeReducer(
    state = {
        currentTheme: createMuiTheme({
            direction: 'ltr',
            typography: {
                useNextVariants: true,
                fontFamily: ['Montserrat', 'sans-serif'].join(',')
            }
        }),
        jss: create({ plugins: [...jssPreset().plugins, rtl()] }),
        generateClassName: createGenerateClassName()
    },
    action
) {
    switch (action.type) {
        case THEME_CHANGED:
            return Object.assign({}, state, {
                currentTheme: action.currentTheme
            });
        default:
            return state;
    }
}
