import axios from 'axios';

export const questionService = {
    getQuestion,
};

function getQuestion() {
    return axios
        .get('/api/play?channel=youtube')
        .then((resp) => {
            return resp.data.questions[0].data;
        })
        .catch((err) => {
            console.error(err);
            return null;
            //throw Error('Failed to get questions');
        });
}

function createQuestion() {
    return {
        body: '1+1',

        answers: [1, 55, 88, 987],
    };
}
